<template>
  <div class="mt-3">
    <div :class="{ 'pt-5': !hasFooter }" class="px-2 px-lg-5">
      <div v-if="hasFooter" class="pt-3 px-lg-5">
        <span v-if="canEditFooter" class="d-flex">
          <h4 class="font-weight-bolder mr-50">
            {{ $t('footer.label') }}
          </h4>
          <feather-icon icon="Edit2Icon" size="20" class="text-primary edit-icon icon-button"
            @click="showEditFooterModal()" />
        </span>
        <FooterEdition 
          :canEditFooter="canEditFooter"
          :initial-state="initialFooterState"
          :handle-changed-footer="handleChangedFooter" 
        />
      </div>

      <div v-if="showPoweredBy && isCommunity" class="footer-copyright text-center" :class="hasFooter ? 'py-1' : 'py-3'">
        <!-- Powered by Nectios -->
        <b-link :href="landingURL" target="_blank">
          <b-img :src="brand" alt="Powered by Nectios" class="brand mb-1" />
        </b-link>
      </div>
    </div>
  </div>
</template>
<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import NectiosLogo from '@/assets/images/brand/brand-logo.svg';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'AppFooter',
  components: {
    ContentWithAnchors: () => import('@core/components/string-utils/ContentWithAnchors.vue' /* webpackChunkName: "ContentWithAnchors" */),
    FooterEdition: () => import('./FooterEdition.vue' /* webpackChunkName: "FooterEdition" */),
    quillEditor
  },
  mixins: [GridStatusMixin],
  data() {
    return {
      readOnly: false,
      footerEdited: null,
      isEditingFooter: false,
      savedFooterState: '',
      editorConfig: {
        placeholder:"",
        readOnly: true,
        theme: 'snow',
        "modules": {
          "toolbar": false
        }
      }
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    translatedDescription() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.currentCollective?.footer?.body || this.mainCollective.footer?.body);
    },
    initialFooterState() {
      const currentFooter = this.currentCollective?.footer?.body || this.mainCollective.footer?.body || '';
      return this.footerEdited || currentFooter;
    },
    footerContent() {
      return this.footerEdited || this.footerEdited === '' 
        ? this.footerEdited 
        : this.translatedDescription;
    },
    hasFooter() {
      return true;
    },
    isAdmin() {
      return this.$store.getters.loggedMember?.isStaff;
    },
    canEditLayout() {
      return this.isAdmin && !this.isGridDisabled;
    },
    canEditFooter() {
      return this.canEditLayout && this.isEditingLayout;
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    showFooterContent() {
      const hasFooter = (this.currentCollective.footer || this.footerEdited != null) && !this.$route.path.includes('backoffice');
      return hasFooter;
    },
    landingURL() {
      if (process.env.VUE_APP_LANDING_URL_FROM != null) {
        return process.env.VUE_APP_LANDING_URL_FROM
          .replace('{from}', 'community_footer')
          .replace('{slug}', this.$store.getters.currentCollective?.slug);
      }
      return null;
    },
    brand() {
      return BrandLogo;
    },
    nectiosLogo() {
      return NectiosLogo;
    },
    isCommunity() {
      return this.$route?.params?.communityId || this.$route?.params?.communitySlug;
    },
    showPoweredBy() {
      return !this.mainSpace || this.mainSpace.level === null || this.mainSpace.level > 1;
    }
  },
  watch: {
    initialFooterState: {
      handler(newValue) {
        // console.log('initialFooterState changed:', newValue);
      },
      immediate: true
    },
    footerEdited(value) {
      // console.log('footerEdited changed:', value);
      return value;
    },
  },
  methods: {
    handleChangedFooter(footerStateString) {
      this.footerEdited = footerStateString;
      const body = footerStateString;
      this.$store.dispatch('editFooter', { body });
      this.isEditingFooter = false;
      // this.$bvModal.hide('modal-edit-footer');
    },
    showEditFooterModal() {
      console.log('Opening footer modal with initial state:', this.initialFooterState);
      this.isEditingFooter = true;
      setTimeout(() => {
        this.$bvModal.show('modal-edit-footer');
      }, 100);
    }
  },
};
</script>

<style lang="scss" scoped>
.footer-description::v-deep {
  .ql-container {
    border: 1px solid white !important;
  }
}

.brand {
  height: 32px;
}

.icon-button {
  cursor: pointer;
}
</style>
